<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.dateWiseFarmerStockReleaseReport') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                <b-row v-if="!authUser.warehouse_id">
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-cols-md="4"
                            :label="$t('warehouse_service.farmer_id')"
                            label-for="farmer_id"
                            >
                            <b-form-input
                                id="farmer_id"
                                v-model="search.farmer_id"
                                placeholder=""
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                      <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-cols-md="4"
                            label-for="division_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="division_id"
                            :options="divisionList"
                            id="division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div  class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                      <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-for="region_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouse_config.region') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="search.region_id"
                          :options="regionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div  class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                          <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-cols-md="4"
                          label-for="district_id"
                          slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('warehouse_report.district') }} <span class="text-danger">*</span>
                            </template>
                          <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="regionDistrictLists"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true: null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                          </b-form-select>
                            <div  class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                      <ValidationProvider name="Upazila" vid='upazilla_id' rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-for="upazilla_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('warehouse_report.upazilla') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="search.upazila_id"
                          :options="upazilaList"
                          id="upazilla_id"
                          :state="errors[0] ? false : (valid ? true: null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div  class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        :label="$t('globalTrans.union')"
                        label-for="union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.union_id"
                        :options="unionList"
                        id="union_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                    <ValidationProvider name="Warehouse Type" vid='warehouse_type_id' rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-cols-md="4"
                            label-for="warehouse_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('warehouse_config.warehouse_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="search.warehouse_type_id"
                            :options="warehouseTypeList"
                            id="warehouse_type_id"
                             :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                        <div  class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                    <ValidationProvider name="Warehouse Name" vid='warehouse_id' rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-for="warehouse_name"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_report.warehouse_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.warehouse_id"
                            :options="warehouseList"
                            id="commodity_group_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div  class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                    <ValidationProvider name="Farmer Name" vid='farmer_id' rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-for="farmer_name"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_service.farmer_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.farmer_id"
                            :options="farmerList"
                            id="farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="''" >{{ farmerLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div  class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-cols-md="4"
                            :label="$t('globalTrans.from_date')"
                            label-for="date"
                        >
                            <flat-pickr class="form-control"
                                        v-model="search.from_date"
                                        :placeholder="$t('globalTrans.select_date')"
                            ></flat-pickr>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        :label="$t('globalTrans.to_date')"
                        label-for="to_date"
                        >
                        <flat-pickr class="form-control"
                                    v-model="search.to_date"
                                    :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4">
                        <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.submit')}}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.dateWiseFarmerStockReleaseReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title= "headerInfo"
                  worksheet="Date Wise Farmer’s Stock Release Report Sheet"
                  name="date-wise-farmer-stock-release-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
                <!-- <export-excel
                    class="btn btn-danger"
                    :data="excelData"
                    worksheet="Warehouse Wise Farmer’s Information Report Sheet"
                    type="csv"
                    name="filename.xls">
                    {{ $t('globalTrans.export_csv') }}
                </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id" :loading-state="loading">
                          {{ $t('warehouse_report.dateWiseFarmerStockReleaseReport') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_service.farmer_name') }}: </strong> {{ getFarmerName(search.farmer_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.from_date') }}: </strong> {{ getSearchDate(search.from_date) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.to_date') }}: </strong> {{ getSearchDate(search.to_date) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.date') }}</b-th>
                            <b-td>{{ new Date().toLocaleDateString("en-US") | dateFormat }}</b-td>
                            <b-td></b-td><b-td></b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.warehouse_name') }}</b-th>
                            <b-td class="capitalize">{{ getDataById(farmerDetail.warehouse_id, 'warehouseName') }}</b-td>
                            <b-th>{{ $t('warehouse_report.farmer_name') }}</b-th>
                            <b-td class="capitalize">{{ $i18n.locale === 'bn' ? farmerDetail.name_bn : farmerDetail.name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.father_name') }}</b-th>
                            <b-td class="capitalize">{{ $i18n.locale === 'bn' ? farmerDetail.father_name_bn : farmerDetail.father_name }}</b-td>
                            <b-th>{{ $t('warehouse_report.mobile_number') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(farmerDetail.username, { useGrouping: false }) }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.address') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? farmerDetail.address_bn : farmerDetail.address }}</b-td>
                            <b-th>{{ $t('warehouse_report.land_area') }}</b-th>
                            <b-td>{{ $n(farmerDetail.land_area) }}</b-td>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                          <!-- <caption class="text-center" style="font-weight:bold; text-color:white">{{ $t('warehouse_report.dateWiseFarmerStockReleaseReport') }}</caption> -->
                        <colgroup><col><col></colgroup>
                        <colgroup><col><col><col></colgroup>
                        <colgroup><col><col></colgroup>
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('globalTrans.date') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_stock') }}</b-th>
                            <b-th>{{ $t('warehouse_report.release_stock') }}</b-th>
                            <b-th>{{ $t('warehouse_report.remaining_stock') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-for="(infoDetail, index) in dayWiseReport" :key="index">
                                <b-tr :key="index" >
                                  <b-td>{{ $n(index+1) }}</b-td>
                                  <b-td>{{ infoDetail.store_date | dateFormat }}</b-td>
                                  <b-td class="capitalize">{{ $i18n.locale === 'bn' ? infoDetail.crop_type_name_bn : infoDetail.crop_type_name }}</b-td>
                                  <b-td class="capitalize">{{ $i18n.locale === 'bn' ? infoDetail.crop_name_bn : infoDetail.crop_name }}</b-td>
                                  <b-td>{{ $n(infoDetail.total_quantity) }}</b-td>
                                  <b-td>{{ $n(infoDetail.release_quantity) }}</b-td>
                                  <b-td>{{ $n(infoDetail.remaining_quantity) }}</b-td>
                                </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-td colspan="4" variant="secondary" class="text-left">
                              {{ $i18n.locale === 'en' ? 'Gross Total :' : 'সর্ব মোট :' }}<b></b>
                            </b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalStock) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalRelease) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalRemaining) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { dateWiseFarmerStockReleaseReport } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
Vue.use(excel)

const excelColumn = {
  'Store Date': 'storeDate',
  'Crop Type': 'cropType',
  'Crop Name': 'cropName',
  'Total Quantity(Quintal)': 'totalQuantity',
  'Release Quantity(Quintal)': 'releaseQuantity',
  'Remaining Quantity(Quintal)': 'remainingQuantity'
}

const excelColumnBn = {
  'রাখার তারিখ': 'storeDate',
  'শস্যের ধরণ': 'cropType',
  'শস্য নাম': 'cropName',
  'মোট স্টক(কুইন্টাল)': 'totalQuantity',
  'রিলিজ স্টক(কুইন্টাল)': 'releaseQuantity',
  'বাকি স্টক(কুইন্টাল)': 'remainingQuantity'
}

export default {
   mixins: [ModalBaseMasterList],
  components: {
      ListReportHead,
      ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      division_id: 0,
      region_id: 0,
      totalStock: '',
      totalRelease: '',
      totalRemaining: '',
    search: {
        farmer_id: '',
        farmerListId: 0,
        org_id: 13,
        region_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        from_date: '',
        to_date: ''
      },
      testId: 0,
      indexes: 0,
      farmerDetail: {},
      dayWiseReport: [],
      dateWiseFarmerReport: [],
      farmerReport: [],
      dateWiseReport: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      excelData: [],
      tempList: [],
      warehouseList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      districtLoading: false,
      farmerLoading: false
    }
  },
  mounted () {
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  computed: {
     loading () {
       return this.$store.state.commonObj.loading
     },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + ' ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + ' ' + this.$t('warehouse_service.farmer_name') + ' : ' + this.getFarmerName(this.search.farmer_id)
      const thirdRow = this.$t('globalTrans.from_date') + ' : ' + this.getSearchDate(this.search.from_date) + ' ' + this.$t('globalTrans.to_date') + ' : ' + this.getSearchDate(this.search.to_date)
      return [this.$t('warehouse_report.dateWiseFarmerStockReleaseReport'), '', firstRow, secondRow, thirdRow, '']
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    warehouseTypeList () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    dataCustomizeExcel () {
        const footerData = this.getFooter()
        const list = this.dayWiseReport
        const header = this.getHeaderExcel()
        const customizeData = list.map(item => {
            if (this.$i18n.locale === 'bn') {
                item.storeDate = this.$n(parseInt(item.store_date.split('-')[2]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[1]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[0]), { useGrouping: false })
                item.cropType = item.crop_type_name_bn
                item.cropName = item.crop_name_bn
                item.totalQuantity = this.$n(item.total_quantity)
                item.releaseQuantity = this.$n(item.release_quantity)
                item.remainingQuantity = this.$n(item.remaining_quantity)
            } else {
                item.storeDate = this.$n(parseInt(item.store_date.split('-')[2]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[1]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[0]), { useGrouping: false })
                item.cropType = item.crop_type_name
                item.cropName = item.crop_name
                item.totalQuantity = this.$n(item.total_quantity)
                item.releaseQuantity = this.$n(item.release_quantity)
                item.remainingQuantity = this.$n(item.remaining_quantity)
            }
            return Object.assign({}, item)
        })
        const custom = header.concat(customizeData)
        const newDataxl = custom.concat(footerData)
        return newDataxl
    }
  },
  watch: {
    loading: function (newVal, oldVal) {
        if (newVal) {
        this.loadData()
        }
    },
    'search.region_id': function (newVal, oldVal) {
        this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    },
    'search.warehouse_id': function (newVal, oldVal) {
        this.getFarmerList(newVal)
    }
  },
  methods: {
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: ''
      }
    },
    searchData () {
        if (this.search.farmer_id) {
            this.loadData()
            this.showData = true
            this.showHeading = true
        }
    },
    getDataById (Id, master) {
        if (master === 'warehouseName') {
            if (!Id) {
                return null
            } else {
                const warehouseId = parseInt(Id)
                 const warehouseName = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === warehouseId)
                 return this.$i18n.locale === 'en' ? warehouseName.text_en : warehouseName.text_bn
            }
        }
    },
    async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.dayWiseReport = []
      this.dateWiseFarmerReport = []
      this.farmerReport = []
      this.dateWiseReport = []
      this.totalStock = ''
      this.totalRelease = ''
      this.totalRemaining = ''
      this.farmerDetail = {}
        await RestApi.getData(warehouseServiceBaseUrl, dateWiseFarmerStockReleaseReport, this.search).then(response => {
            if (response.success) {
                this.dataList(response)
            } else {
                this.dayWiseReport = []
                this.dateWiseFarmerReport = []
                this.farmerReport = []
                this.dateWiseReport = []
                this.totalStock = ''
                this.totalRelease = ''
                this.totalRemaining = ''
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        }, error => {
          if (error) {
            //
          }
          this.$store.commit('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    async dataList (data) {
        var tS = 0
        var tRel = 0
        var tRem = 0
        if (data.data) {
            const id = data.data.farmer_id
            this.dateWiseFarmerReport = data.data[0]
            await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
                if (response) {
                    var list = response.data.find(document => document.username === id)
                    this.farmerDetail = list
                }
            })
        } else {
            this.dateWiseFarmerReport = []
        }

        var myArray = this.dateWiseFarmerReport
        var groups = {}
        for (var i = 0; i < myArray.length; i++) {
                var cropType = myArray[i].crop_type_id
                if (!groups[cropType]) {
                    groups[cropType] = []
                }
                var newData = {
                    crop_id: myArray[i].crop_id,
                    total_quantity: myArray[i].total_quantity,
                    release_quantity: myArray[i].release_quantity,
                    remaining_quantity: myArray[i].remaining_qnty,
                    store_date: myArray[i].store_date
                }
                groups[cropType].push(newData)
            }
        myArray = []
        for (var Name in groups) {
            myArray.push({ crop_type_id: Name, data: groups[Name] })
        }

        const newarr = myArray
        this.dateWiseFarmerReport = newarr
        this.dateWiseFarmerReport.forEach(item => {
            item.data.forEach((listItem, index) => {
                if (index === 0) {
                    listItem.crop_type_id = item.crop_type_id
                } else {
                     listItem.crop_type_id = null
                }
            })
        })
        const pdf = []
        this.farmerReport = this.dateWiseFarmerReport
        this.farmerReport.forEach((item) => {
            item.data.forEach((list) => {
                pdf.push(list)
            tS = tS + list.total_quantity
            tRel = tRel + list.release_quantity
            tRem = tRem + list.remaining_quantity
            })
        })
        this.totalStock = tS
        this.totalRelease = tRel
        this.totalRemaining = tRem
        const CustomData = pdf
        const warehouseTypeList = this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
        const commodityNameList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
        const listData = CustomData.map(item => {
                const warehouseTypeObject = warehouseTypeList.find(warehouseType => warehouseType.value === parseInt(item.crop_type_id))
                const commodityNameObject = commodityNameList.find(commodityName => commodityName.value === item.crop_id)
                var warehouseTypeData = {
                                crop_type_name: null,
                                crop_type_name_bn: null
                                }
                if (warehouseTypeObject) {
                        warehouseTypeData = {
                                crop_type_name: warehouseTypeObject !== undefined ? warehouseTypeObject.text_en : '',
                                crop_type_name_bn: warehouseTypeObject !== undefined ? warehouseTypeObject.text_bn : ''
                                }
                        } else {
                        warehouseTypeData = {
                                crop_type_name: null,
                                crop_type_name_bn: null
                                }
                        }
                var commodityNameData = {
                                crop_name: null,
                                crop_name_bn: null
                        }
                if (commodityNameObject) {
                        commodityNameData = {
                                crop_name: commodityNameObject !== undefined ? commodityNameObject.text : '',
                                crop_name_bn: commodityNameObject !== undefined ? commodityNameObject.text_bn : ''
                        }
                }
                return Object.assign({}, item, warehouseTypeData, commodityNameData)
            })
        this.dayWiseReport = listData
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        return listData
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const head = this.getHeader()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.release_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.remaining_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]

      rowData.unshift(labels)
      const finalRow = head.concat(rowData)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Wise Farmer’s Information Report' : 'গুদাম অনুযায়ী কৃষকের তথ্য প্রতিবেদন'
      const columnWids = ['10%', '15%', '15%', '15%', '15%', '15%', '15%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_service.farmer_name')} : ${this.getFarmerName(this.search.farmer_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header3 = [
        { text: `${this.$t('globalTrans.from_date')} : ${this.getSearchDate(this.search.from_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.to_date')} : ${this.getSearchDate(this.search.to_date)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2, header3]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, finalRow, columnWids, extraData, '' +
          'date-wise-farmer-stock-release-report')
    },
    getHeader () {
        const date = new Date().toLocaleDateString('en-US')
        var header = [
            [{ text: this.$t('globalTrans.date') }, { text: this.$n(date.split('/')[0], { useGrouping: false }) + '/' + this.$n(date.split('/')[1], { useGrouping: false }) + '/' + this.$n(date.split('/')[2], { useGrouping: false }) }, { }, { }, { }, { }, { }],
            [{ text: this.$t('warehouse_report.warehouse_name') }, { text: this.$i18n.locale === 'bn' ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_bn : this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text },
            { }, { text: this.$t('warehouse_report.farmer_name') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.name_bn : this.farmerDetail.name }, { }, { }],
            [{ text: this.$t('warehouse_report.father_name') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.father_name_bn : this.farmerDetail.father_name }, { },
            { text: this.$t('warehouse_report.mobile_number') }, { text: this.$i18n.locale === 'bn' ? '০' + this.$n(this.farmerDetail.username, { useGrouping: false }) : '0' + this.$n(this.farmerDetail.username, { useGrouping: false }) }, { }, { }],
            [{ text: this.$t('warehouse_report.address') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.address_bn : this.farmerDetail.address }, { },
            { text: this.$t('warehouse_report.land_area') }, { text: this.$n(this.farmerDetail.land_area) }, { }, { }]
        ]
        return header
    },
    getHeaderExcel () {
        const date = new Date().toLocaleDateString('en-US')
            const warehousenamebn = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id) ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_bn : null
            const warehousename = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id) ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_en : null
            const farmer = this.$i18n.locale === 'bn' ? this.farmerDetail.name_bn : this.farmerDetail.name
            const mobile = this.$i18n.locale === 'bn' ? '০' + this.$n(this.farmerDetail.username, { useGrouping: false }) : '0' + this.$n(this.farmerDetail.username, { useGrouping: false })
            const father = this.$i18n.locale === 'bn' ? this.farmerDetail.father_name_bn : this.farmerDetail.father_name
            const address = this.$i18n.locale === 'bn' ? this.farmerDetail.address_bn : this.farmerDetail.address
            const land = this.$n(this.farmerDetail.land_area)
        const head = [{
            cropName: null,
            cropType: this.$n(parseInt(date.split('/')[0]), { useGrouping: false }) + '-' + this.$n(parseInt(date.split('/')[1]), { useGrouping: false }) + '-' + this.$n(parseInt(date.split('/')[2]), { useGrouping: false }),
            releaseQuantity: null,
            remainingQuantity: null,
            storeDate: this.$t('globalTrans.date'),
            totalQuantity: null
            },
            {
            cropName: null,
            cropType: this.$store.locale === 'en' ? warehousename : warehousenamebn,
            releaseQuantity: farmer,
            remainingQuantity: null,
            storeDate: this.$t('warehouse_report.warehouse_name'),
            totalQuantity: this.$t('warehouse_report.farmer_name')
            },
            {
            cropName: null,
            cropType: father,
            releaseQuantity: mobile,
            remainingQuantity: null,
            storeDate: this.$t('warehouse_report.father_name'),
            totalQuantity: this.$t('warehouse_report.mobile_number')
            },
            {
            cropName: null,
            cropType: address,
            releaseQuantity: land,
            remainingQuantity: null,
            storeDate: this.$t('warehouse_report.address'),
            totalQuantity: this.$t('warehouse_report.land_area')
            }]

        return head
    },
    getFooter () {
        const footer = [{
            cropName: null,
            cropType: null,
            releaseQuantity: this.$n(this.totalRelease),
            remainingQuantity: this.$n(this.totalRemaining),
            storeDate: this.$t('sitePreference.grossTotal'),
            totalQuantity: this.$n(this.totalStock)
            }]
        return footer
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: 'date' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'total_quantity' },
          { key: 'release_quantity' },
          { key: 'remaining_quantity' }
        ]
      var serial = 0
      var newArray = []
      newArray = this.dayWiseReport
      var listData = newArray.map(item => {
              serial += 1
                const rowData = keys.map((keyItem, index) => {
                    if (keyItem.key === 'serial_no') {
                        return { text: this.$n(serial) }
                    }
                    if (keyItem.key === 'date') {
                        return { text: this.$n(parseInt(item.store_date.split('-')[2]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[1]), { useGrouping: false }) + '/' + this.$n(parseInt(item.store_date.split('-')[0]), { useGrouping: false }) }
                    }
                    if (keyItem.key === 'crop_type_name') {
                        return { text: item.crop_type_name }
                    }
                    if (keyItem.key === 'crop_type_name_bn') {
                        return { text: item.crop_type_name_bn }
                    }
                    if (keyItem.key === 'crop_name') {
                        return { text: item.crop_name }
                    }
                    if (keyItem.key === 'crop_name_bn') {
                        return { text: item.crop_name_bn }
                    }
                    if (keyItem.key === 'total_quantity') {
                        return { text: this.$n(item.total_quantity) }
                    }
                    if (keyItem.key === 'release_quantity') {
                        return { text: this.$n(item.release_quantity) }
                    }
                    if (keyItem.key === 'remaining_quantity') {
                        return { text: this.$n(item.remaining_quantity) }
                    }
                return { text: item[keyItem.key] }
                })
                 return rowData
      })
      const total = [
            this.$i18n.locale === 'en' ? { text: 'GrossTotal :' } : { text: 'সর্ব মোট :' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: this.$n(this.totalStock) },
            { text: this.$n(this.totalRelease) },
            { text: this.$n(this.totalRemaining) }
      ]
      listData.push(total)
      return listData
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                    }
                })
            }
        })
      this.districtLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    async getFarmerList (warehouseId = null) {
      this.farmerLoading = true
        await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
            if (response) {
                var list = response.data.filter(document => document.warehouse_id === warehouseId)
            }
            const flist = list.map(obj => {
                // if (this.$i18n.locale === 'bn') {
                //     return { value: obj.username, text: obj.name_bn }
                // } else {
                //     return { value: obj.username, text: obj.name }
                // }
              const customItem = {
                value: obj.username,
                text: this.$i18n.locale === 'bn' ? obj.name_bn : obj.name,
                text_en: obj.name,
                text_bn: obj.name_bn
              }
              return Object.assign({}, obj, customItem)
            })
            this.farmerList = flist
            return flist
        })
      this.farmerLoading = false
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getOrganizationName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.organizationtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFarmerName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.farmerList.find(item => item.value === id)
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getSearchDate (date) {
      let dateValue = this.$t('globalTrans.all')
      if (date) {
        dateValue = this.$options.filters.dateFormat(date)
      }
      return dateValue
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
